.container-lineage {
    height: 100%;
    width: 100%;
	  box-sizing: border-box;
    z-index: -1;
    overflow-x: hidden;
}

.container-lineage:before {
    overflow-x: hidden;
	height: 100%;
	width: 100%;
  content: ' ';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  background-image: image-url('LongNying_Refuge_Web.jpg');
  background-repeat: no-repeat;
  background-position: 0% 2%;
  background-size: cover;
  opacity: 0.16;
  z-index: -1;

}    

.image {
	width: 155px;
	height: 155px;
	margin: 5px;
	background-repeat: no-repeat;
}

.image-bar {
  padding-left: 10%;
  width: 25%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  position: absolute;
  overflow-x: hidden;
}

.image-text {
    position: absolute;
    left: 25%;
    width: 75%;
    height: 100%;
    padding-left: 12%;
    padding-top: 5%;
    overflow: auto;
    box-sizing: border-box;
    z-index: 5;
    color: #000;
}

.image-text-center {
	margin-left: 10px;
	width: 65%;
  padding: 15px;
  text-align: justify;
}

.text-center {
	text-align: center;
}

.image-large {
	width: 260px;
	height: 260px;
	margin: 0px auto;
	padding-bottom: 5px;
}
