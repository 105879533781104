.inverted {
  margin-top: 0px;
  top: 0;
  left: 0;
  border-bottom: 20px solid hsla(5, 85%, 36%, 1);
  height: 50px;
  z-index: 10;
}

.image-wide {
  height: 180px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.auction-item {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.modalDialog {
  display: none;
  position: fixed;
  font-family: Arial, Helvetica, sans-serif;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  z-index: 99999;
  opacity:0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
}

.modalDialog:target {
  display: block;
  opacity:1;
  pointer-events: auto;
}

.modalDialog > div {
  position: relative;
  margin: 10% auto;
  padding: 5px; 
}

.close {
  background: #606061;
  color: #FFFFFF;
  line-height: 25px;
  position: absolute;
  right: -12px;
  text-align: center;
  top: -10px;
  width: 24px;
  text-decoration: none;
  font-weight: bold;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  -moz-box-shadow: 1px 1px 3px #000;
  -webkit-box-shadow: 1px 1px 3px #000;
  box-shadow: 1px 1px 3px #000;
}

.close:hover { background: #00d9ff; }

.modal-image {
  width: 70%;
  height: 70%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
