body,
html {
    min-height: 100vh;
    margin: 0;
}

iframe {
    width: 100%;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.header-nav {
    width: 100%;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
    padding: 2rem;
    position: relative;
}

.header-bar {
    min-height: 27px;
    background-color: #aa1b0e;
    background: #aa1b0e;
    border-top: 7px solid #eeba2f;
    margin-top: 7px;
}

.header-links {
    @media (min-width: 780px) {
        padding: 1rem;
    }
}

.header-rainbow {
    height: 5px;
    background: -webkit-linear-gradient(
        90deg,
        #019122 10%,
        #d40902 10%,
        #d40902 20%,
        #edeb00 20%,
        #edeb00 30%,
        #fff 30%,
        #fff 40%,
        #02267e 40%,
        #02267e 50%,
        #019122 50%,
        #019122 60%,
        #d40902 60%,
        #d40902 70%,
        #edeb00 70%,
        #edeb00 80%,
        #fff 80%,
        #fff 90%,
        #02267e 90%,
        #02267e 100%
    );
    background: -moz-linear-gradient(
        90deg,
        #019122 10%,
        #d40902 10%,
        #d40902 20%,
        #edeb00 20%,
        #edeb00 30%,
        #fff 30%,
        #fff 40%,
        #02267e 40%,
        #02267e 50%,
        #019122 50%,
        #019122 60%,
        #d40902 60%,
        #d40902 70%,
        #edeb00 70%,
        #edeb00 80%,
        #fff 80%,
        #fff 90%,
        #02267e 90%,
        #02267e 100%
    );
    background: linear-gradient(
        90deg,
        #019122 10%,
        #d40902 10%,
        #d40902 20%,
        #edeb00 20%,
        #edeb00 30%,
        #fff 30%,
        #fff 40%,
        #02267e 40%,
        #02267e 50%,
        #019122 50%,
        #019122 60%,
        #d40902 60%,
        #d40902 70%,
        #edeb00 70%,
        #edeb00 80%,
        #fff 80%,
        #fff 90%,
        #02267e 90%,
        #02267e 100%
    );
    border-bottom: 1px solid #eee;
}

.header-gradient {
    height: 1px;
    background: -webkit-linear-gradient(90deg, #eeba2f 20%, #aa1b0e);
    background: -moz-linear-gradient(90deg, #eeba2f 20%, #aa1b0e);
    background: linear-gradient(90deg, #eeba2f 20%, #aa1b0e);
}

.title {
    font-family: "Bellefair", serif;
    font-size: 46px;
    font-weight: 400;
    letter-spacing: 2.5px;
    text-rendering: optimizeLegibility;
    line-height: 70px;

    @media (max-width: 780px) {
        letter-spacing: 0px;
    }
}

.page-heading .title {
    font-size: 26px;
}

.subtitle {
    font-family: "Bellefair", serif;
    font-size: 26px;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

.header-nav .title {
    margin-left: 15px;
}

.mailing-list {
    border: 2px solid gold;
    box-shadow: 0 0 0 2px #dc523e;
    border-radius: 6px;
    margin-bottom: 30px;
    padding: 15px;
}

.red {
    color: hsla(5, 85%, 36%, 1);
}

@media (max-width: 780px) {
    .title {
        font-family: "Bellefair", serif;
        font-weight: 400;
        color: hsla(5, 85%, 36%, 1);
        text-rendering: optimizeLegibility;
        margin-right: 20px;
    }
}

.logo {
    position: absolute;
    top: 1.4vh;
    left: calc(1% + 2.5vw);
    width: 235px;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 780px) {
        display: none;
    }
}

#logo {
    transition: transform 1s ease;
}

.login {
    width: 60%;
    height: 60%;
    margin: 0px auto;
}

.v-fixed {
    height: 100%;
}

.body {
    width: 100%;
    position: relative;
}

.content-body {
    padding-top: 15px;
    position: relative;
    width: 80%;
    margin: 0px auto;

    @media (min-width: 1400px) {
        width: 60%;
    }
}

.nav {
    position: absolute;
    width: 160px;
    padding-top: 10px;
    padding: 5px;
}

.mobile-link {
    display: none;
}

@media (max-width: 780px) {
    .header-bar {
        display: flex;
        justify-content: flex-end;
    }
    .nav-links {
        display: none;
        overflow: hidden;
    }
    .link {
        display: block;
        float: none;
        width: 100%;
    }
    .mobile-link {
        display: block;
        float: right;
        padding-right: 10px;
        padding-top: 5px;
        width: 34px;
        cursor: pointer;
    }
    .mobile-wrapper {
        width: 80%;
        margin: 0px auto;
    }
}

.nav-links {
    height: 25px;
    padding-left: calc(1% + 2.5vw + 235px);
    text-align: justify;
    padding-right: 25px;

    @media (max-width: 780px) {
        width: 80%;
        padding-left: 2rem;
        padding-right: 0px;
        margin: 0px auto;
    }
}

.sub-links {
    display: none;

    @media (max-width: 780px) {
        display: inline-block;
        width: 100%;
        padding-left: 15px;
        font-size: 13px;
        font-color: #333;
    }
}
.nav-links:after {
    content: "";
    display: inline-block;
    width: 100%;
}

.short-links {
    width: 50%;
    float: right;
    text-align: justify;
}

.short-links .link {
    float: right;
    width: 40%;
}

.link {
    display: inline-block;
}

.q-a {
    width: 80%;
    text-align: left;
    text-alignment: justify;
    margin: 2rem auto;
}

.q-a .answer {
    color: #dc523e;
}

.link a {
    color: #222;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
}

.link a:hover {
    color: hsla(5, 85%, 36%, 1);
}

.mobile-link a {
    font-size: 16px;
    color: hsla(5, 85%, 36%, 1);
    text-decoration: none;
}

.mobile-link a:hover {
    color: hsla(5, 85%, 36%, 1);
}

.black {
    font-color: black;
    color: black;
    text-decoration: none;
}

.blue {
    color: #02267e;
}

.gold {
    color: #eeba2f;
}

.orange {
    colord: #dc523e;
}

.green {
    color: #019122;
}

.last {
    border-right: none;
}

.first {
    border-left: 1px solid hsla(5, 85%, 36%, 1);
}

table {
    border-collapse: collapse;
    border-color: none;
}

.medium {
    width: 200px;
}

.large {
    width: 350px;
}

.library {
    margin: 0px auto;
    padding-left: 40px;
}

.library th {
    border-bottom: 1px solid black;
}

.library tr:nth-child(2n) {
    background-color: #eee;
}

.library .download {
    background-image: image-url("circle-arrow-down.png");
    background-size: 25px;
    width: 25px;
    height: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    display: inline-block;
}

.top-padded {
    padding-top: 15px;
}

.field .select-multiple {
    height: 100px;
}

.field_with_errors {
    padding: 2px;
    background-color: red;
    display: table;
}

.right {
    float: right;
}

.button.solid {
    background-color: #eee;
    border: 1px solid black;
}

.white {
    color: white;
}

.right {
    float: right;
}

.block {
    display: block;
}

.inline {
    display: inline;
}

label {
    min-width: 150px;
}

.header-right {
    float: right;
}

.header-name {
    background-color: #fff;
    height: 25px;
    padding: 0px 10px;
    font-size: 14px;
    margin-right: 15px;
    color: black;
}

.header-drop {
    background-image: image-url("down-button.png");
    background-size: 9px 9px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    width: 9px;
    height: 9px;
    float: right;
    margin-top: 8px;
    margin-left: 5px;
}

.center-image {
    margin-bottom: 30px;
    margin-left: auto;
    margin-right: auto;
    height: 400px;
    width: 360px;
    position: relative;
}

.post-image {
    height: 400px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 3px 3px 8px #666;
    overflow: hidden;
}

.center-post {
    width: 95%;
    margin: 0px auto;
    margin-top: 15px;

    @media (min-width: 768px) {
        width: 80%;
    }
}

.center-text {
    text-align: center;
}

img.center {
    display: block;
    margin: 0 auto;
}

.error {
    color: red;
    margin-right: 5px;
}

.error_message {
    color: red;
}

#error_explanation h2 {
    text-align: left;
    font-weight: bold;
    padding: 5px 5px 5px 15px;
    font-size: 12px;
    margin: -7px;
    margin-bottom: 0px;
    background-color: #c00;
    color: #fff;
}

#error_explanation ul li {
    font-size: 12px;
    list-style: square;
}

.g-recaptcha {
    padding-bottom: 10px;
}

.home-button {
    position: fixed;
    right: 20px;
    top: 20px;
    z-index: 10;
    width: 50px;
    height: 25px;
    text-align: center;
    background-color: white;
    padding: 3px;
    border: 1px solid black;
    border-radius: 10px;
}

.black-link {
    color: black;
}

.black-link:hover {
    color: #1eaedb;
}

.no-margin {
    margin: 0px;
}

.main-image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.main-image img {
    width: 100%; /* Set width to 100% of the container */
    height: auto; /* Height is adjusted automatically to maintain aspect ratio */
}

.page-heading {
    text-align: center;
    margin-top: 20px;
}

.c-background {
    background-image: url("/images/china.png");
    background-color: gold;
    background-blend-mode: lighten;
}

.gold-border {
    border-top: 2px solid gold;
    border-bottom: 2px solid gold;
    padding-top: 20px;
    text-align: center;
}

.maroon-border {
    border-top: 2px solid #aa1b0e;
}

.push-bottom {
    margin-bottom: 25px;
}

.submenu {
    width: 100%;
    display: table;
    height: 25px;
    font-size: 0.9em;
    line-height: 1.9em;
    padding: 5px;
}

.submenu .nav-links {
    width: 85%;
    margin: 0px auto;
}

.donate-center {
    width: 150px;
    margin: 0px auto;
}

.donate-center form {
    margin-bottom: 0px;
}

.donate-center input {
    margin-bottom: 0px;
}

.donate-car {
    height: 70px;

    @media (min-width: 780px) {
        position: relative;
        top: 30px;
    }
}
