.index-footer {
    margin-top: 15px;
    margin-bottom: 15px;
    width: 100%;
}

.grey-border {
    border-top: 1px solid #eee;
}

.footer {
    height: 50px;
}

.footer-links {
    height: 25px;
    margin: 15px auto;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
}

.footer-links .link {
    width: 30%;;
    font-size: 14px;
}

.address {
    width: 100%;
    text-align: center;
    font-size: 14px;
}
