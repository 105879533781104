.program-container {
    display: flex;
    margin: 30px 15px;
    justify-content: center;
    flex-wrap: wrap;

    @media (min-width: 768px) {
      flex-wrap: nowrap;
      justify-content: left;
    };
}

.program-image {
  padding-bottom: 15px;
}

.program-details {
    margin-left: 15px;
    width: 100%;
}

.program-image img {
    width: 250px;
    height: 250px;
}

.program-title {
    border-bottom: 1px solid #DC523E;
    font-weight: bold;
    margin-bottom: 15px;
}

.program-date {
    margin-bottom: 5px;
    text-decoration: bold;
}

.program-description {
}
